<template>
  <div>
    <main-view></main-view>
  </div>
</template>

<script>
import MainView from './views/fly/MainView.vue'

export default {
  components: { MainView },
  data () {
    return {
      
    }
  }
}
</script>

<style>

</style>
