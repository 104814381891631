<template>
  <div>
    <!-- 头部 -->
     <div class="header w">
     </div>

     <!-- 导航栏 -->
      <div class="nav">
        <!-- 大盒子 -->
         <div class="box">
            <!-- 往返成人经济舱 -->
            <div class="choose">
            <span class="font">往返</span>
            <span class="tan"></span>
            <span class="font">成人</span>   
            <span class="tan"></span>         
            <span class="font">经济舱</span>
            <span class="tan"></span>
            </div>

            <!-- 从哪儿出发 -->
            <div class="where">
              <div class="where-begin"></div>
              <input type="text" placeholder="  从哪儿出发">
            </div>

            <!-- 往返箭头 -->
            <span class="go"></span>
            <span class="back"></span>

            <!-- 到哪了 -->
            <div class="dao-where">
              <div class="dao-where-end">
              <input type="text" placeholder="  到哪了">
            </div>
            </div>

          <!-- 日期 -->
           <div class="date">
            <!-- 日期图标 -->
              <span class="calendar"></span>
             <!-- 输入框1 -->
              <input class="begin-date" type="text" placeholder="  2月23日 周五">
              <em>|</em>
             <!-- 输入框2 -->
              <input class="begin-date" type="text" placeholder="  2月25日 周天">
           </div>

           <div class="current-price">
            <input type="text" placeholder="  近日航班价格 ">
           </div>

          <div class="current-fly">
            <input type="text" placeholder="  附近机场的航班 ">
          </div>
         </div>

      </div>

      <!-- 警告 -->
      <span class="alert"></span>

      <!-- 提示 -->
      <div class="tip">
          <span >您查询的2月23日北京到广州的航班,建议在四月份进行购买哟~</span> 
          <span >通过数据对比,比途发现4月份北京到广州的航班最划算,8月份价格偏高~</span>
      </div>  

      <!-- 列车1 -->
      <div class="train1">
        <div class="pic1">
          <img src="https://www.12306.cn/index/images/logo@2x.png" alt="">
        </div>

        <!-- train1车次名 -->
        <div class="train-name1">
          <h3>和谐号<br>K421</h3>
        </div>

        <!-- train1起始时间 -->
         <div class="timeBegin1">
          <div class="begin1">
            <span>8:43</span>
          </div>
          <div class="location1">北京西站</div>
         </div>

         <!-- train1航班信息 -->
         <div class="box-distance1">
          <div class="distance1">
            <span>2330km</span>
          </div>
          <div class="time1">
            <span>8小时1分钟</span>
          </div>
         </div>

         <!-- train1结束时间 -->
        <div class="timeAfter1">
         <div class="after1">
            <span>11:22</span>
          </div>
          <div class="location5">广州南站</div>
        </div>

        <!-- train1价格 -->
         <div class="train-price1">
          <span>价格:</span>
          <div class="price1">530</div>
         </div>

         <!-- 去购买按钮 -->
         <button>去购买</button>

      </div>


      <!-- 列车2 -->
      <div class="train2">
        <div class="pic2">
          <img src="https://pavo.elongstatic.com/i/ori/1i8HMZ04KqY.jpg" alt="">
        </div>

        <!-- train2车次名 -->
        <div class="train-name2">
          <h3>东方航空<br>GJ8009</h3>
        </div>

        <!-- train2起始时间 -->
         <div class="timeBegin2">
          <div class="begin2">
            <span>8:45</span>
          </div>
          <div class="location2">北京西站</div>
         </div>

         <!-- train2航班信息 -->
         <div class="box-distance2">
          <div class="distance2">
            <span>2330km</span>
          </div>
          <div class="time2">
            <span>8小时1分钟</span>
          </div>
         </div>

         
         <!-- train2结束时间 -->
        <div class="timeAfter2">
         <div class="after2">
            <span>11:22</span>
          </div>
          <div class="location6">广州南站</div>
        </div>

         <!-- train2价格 -->
         <div class="train-price2">
          <span>价格:</span>
          <div class="price2">530</div>
         </div>

        <!-- 去购买按钮 -->
         <button>去购买</button>


      </div>

      <!-- 列车3 -->
      <div class="train3">
        <div class="pic3">
          <img src="https://qimgs.qunarzz.com/wpf_newmpic_001/54f70ce8dd89589d3fd2289dcc25b7d8.jpg" alt="">
        </div>

        <!-- 车次名 -->
        <div class="train-name3">
          <h3>东方航空<br>GJ8009</h3>
        </div>

        <!-- train3起始时间 -->
         <div class="timeBegin3">
          <div class="begin3">
            <span>8:45</span>
          </div>
          <div class="location3">北京西站</div>
         </div>

         <!-- train3航班信息 -->
         <div class="box-distance3">
          <div class="distance3">
            <span>2330km</span>
          </div>
          <div class="time3">
            <span>8小时1分钟</span>
          </div>
         </div>

        <!-- train3结束时间 -->
        <div class="timeAfter3">
         <div class="after3">
            <span>11:22</span>
          </div>
          <div class="location7">广州南站</div>
        </div>

         <!-- train1价格 -->
         <div class="train-price3">
          <span>价格:</span>
          <div class="price3">530</div>
         </div>

         <!-- 去购买按钮 -->
         <button>去购买</button>

      </div>

      <!-- 列车4 -->
      <div class="train4">
        <div class="pic4">
          <img src="	https://img.alicdn.com/imgextra/i1/O1CN01gjTU3l1Zm8azDq6bC_!!6000000003236-2-tps-792-192.png" alt="">
        </div>

        <!-- 车次名 -->
        <div class="train-name4">
          <h3>东方航空<br>GJ8009</h3>
        </div>

        <!-- train4起始时间 -->
         <div class="timeBegin4">
          <div class="begin4">
            <span>8:45</span>
          </div>
          <div class="location4">北京西站</div>
         </div>

        <!-- train4航班信息 -->
         <div class="box-distance4">
          <div class="distance4">
            <span>2330km</span>
          </div>
          <div class="time4">
            <span>8小时1分钟</span>
          </div>
         </div>

        <!-- train3结束时间 -->
        <div class="timeAfter4">
         <div class="after4">
            <span>11:22</span>
          </div>
          <div class="location8">广州南站</div>
        </div>

        <!-- train4价格 -->
         <div class="train-price4">
          <span>价格:</span>
          <div class="price4">530</div>
         </div>

         <!-- 去购买按钮 -->
         <button>去购买</button>

      </div>

  </div>
</template>

<script>
export default {

}
</script>

<style>
.w {
  margin: 0;
  padding: 0;
}
.header {
  width: 100%;
  height: 50px;
  background-color: rgb(52, 103, 212)
}
@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?eygm2u');
  src:  url('fonts/icomoon.eot?eygm2u#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?eygm2u') format('truetype'),
    url('fonts/icomoon.woff?eygm2u') format('woff'),
    url('fonts/icomoon.svg?eygm2u#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.nav {
  width: 100%;
  height: 170px;
  /* 防止父子盒子塌陷 */
  overflow: hidden;
  background-color: rgb(67, 133, 245);
}
.tip {
  width: 450px;
  height: 60px;
  margin: 10px auto;
  background-color: rgb(254, 208, 241);
  border-radius: 5px;
}
.tip span{
  text-indent: 2em;
  display: inline-block;
  padding-top: 8px;
  font-size: 13px;
  font-weight: 400;
}
.alert {
  float: left;
  display: block;
  margin-left: 515px;
  margin-top: 15px;
  font-family: 'icomoon';
}
.tip .alert {
  margin-left: -65px;
  font-size: 20px;
}
.train1 {
  width: 770px;
  height: 90px;
  margin: 10px auto;
  border-radius: 7px;
  border: 1px solid black;
}
.train1 .pic1 {
  float: left;
  width: 135px;
  height: 90px;
}
.train1 .pic1 img {
  width: 100%;
  height: 100%;
  margin-left: 35px;
  object-fit: contain;
}
.train-name1 {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  float: left;
  height: 70px;
  width: 90px;
  margin-left: 55px;
  margin-top: 10px;
  border-left: 2px solid black;
}
.train-name1 h3 {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  height: 70px ;
}
.timeBegin1 {
  float: left;
  height: 90px;
  width: 90px;
}
.timeBegin1 .begin1 {
  height: 54px;
  font-size: 28px;
}
.timeBegin1 .begin1 span {
  display: inline-block;
  margin-top: 20px;
  margin-left: 18px;
}
.timeBegin1 .location1 {
  height: 36px;
  text-align: center;
  font-size: 13px;
}
.box-distance1 {
  float: left;
  width: 140px;
  height: 90px;
}
.box-distance1 .distance1 {
  height: 43px;
  width: 140px;
  border-bottom: 2px solid rgb(61, 103, 179);
}
.box-distance1 .distance1 span {
  display: inline-block;
  font-size: 16px;
  margin-left: 40px;
  margin-top: 20px
}
.box-distance1 .time1 span {
  display: inline-block;
  font-size: 10px;
  margin-left: 42px;
  margin-top: 3px;
}
.timeAfter1 {
  float: left;
  height: 90px;
}
.timeAfter1 .after1 {
  height: 54px;
}
.timeAfter1 .location5 {
  font-size: 13px;
  height: 36px;
  margin-left: 30px;
}
.timeAfter1 .after1 span {
  display: inline-block;
  font-size: 28px;
  margin-top: 20px;
  margin-left: 18px;
}
.train-price1 {
  float: left;
  width: 90px;
  height: 70px;
  margin-left: 18px;
  margin-top: 10px;
  border-left: 2px solid black;
}
.train-price1 span {
  display: inline-block;
  font-size: 12px;
  margin-top: 10px;
  margin-left: 15px;
}
.train-price1 .price1 {
  font-size: 28px;
  margin-left: 13px;
}
button {
  line-height: 25px;
  font-size: 13px;
  float: left;
  background-color: #4385f5;
  border-radius: 3px;
  border: none;
  margin-left: -15px;
  margin-top: 42px;
  color:rgb(209, 236, 241)
}
.train2 {
  width: 770px;
  height: 90px;
  margin: 10px auto;
  border-radius: 7px;
  border: 1px solid black;
}
.train2 .pic2 {
  float: left;
  width: 135px;
  height: 90px;
}
.train2 .pic2 img {
  width: 100%;
  height: 100%;
  margin-left: 35px;
  object-fit: contain;
}
.train-name2 {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  float: left;
  height: 70px;
  width: 90px;
  margin-left: 55px;
  margin-top: 10px;
  border-left: 2px solid black;
}
.train-name2 h3 {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  height: 70px ;
}
.timeBegin2 {
  float: left;
  height: 90px;
  width: 90px;
}
.timeBegin2 .begin2 {
  height: 54px;
  font-size: 28px;
}
.timeBegin2 .begin2 span {
  display: inline-block;
  margin-top: 20px;
  margin-left: 18px;
}
.timeBegin2 .location2 {
  height: 36px;
  text-align: center;
  font-size: 13px;
}
.box-distance2 {
  float: left;
  width: 140px;
  height: 90px;
}
.box-distance2 .distance2 {
  height: 43px;
  width: 140px;
  border-bottom: 2px solid rgb(61, 103, 179);
}
.box-distance2 .distance2 span {
  display: inline-block;
  font-size: 16px;
  margin-left: 40px;
  margin-top: 20px
}
.box-distance2 .time2 span {
  display: inline-block;
  font-size: 10px;
  margin-left: 42px;
  margin-top: 3px;
}
.timeAfter2 {
  float: left;
  height: 90px;
}
.timeAfter2 .after2 {
  height: 54px;
}
.timeAfter2 .location6 {
  font-size: 13px;
  height: 36px;
  margin-left: 30px;
}
.timeAfter2 .after2 span {
  display: inline-block;
  font-size: 28px;
  margin-top: 20px;
  margin-left: 18px;
}
.train-price2 {
  float: left;
  width: 90px;
  height: 70px;
  margin-left: 18px;
  margin-top: 10px;
  border-left: 2px solid black;
}
.train-price2 span {
  display: inline-block;
  font-size: 12px;
  margin-top: 10px;
  margin-left: 15px;
}
.train-price2 .price2 {
  font-size: 28px;
  margin-left: 13px;
}
.train3 {
  width: 770px;
  height: 90px;
  margin: 10px auto;
  border-radius: 7px;
  border: 1px solid black;
}
.train3 .pic3 {
  float: left;
  width: 135px;
  height: 90px;
}
.train3 .pic3 img {
  width: 100%;
  height: 100%;
  margin-left: 35px;
  object-fit: contain;
}
.train-name3 {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  float: left;
  height: 70px;
  width: 90px;
  margin-left: 55px;
  margin-top: 10px;
  border-left: 2px solid black;
}
.train-name3 h3 {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  height: 70px ;
}
.timeBegin3 {
  float: left;
  height: 90px;
  width: 90px;
}
.timeBegin3 .begin3 {
  height: 54px;
  font-size: 28px;
}
.timeBegin3 .begin3 span {
  display: inline-block;
  margin-top: 20px;
  margin-left: 18px;
}
.timeBegin3 .location3 {
  height: 36px;
  text-align: center;
  font-size: 13px;
}
.box-distance3 {
  float: left;
  width: 140px;
  height: 90px;
}
.box-distance3 .distance3 {
  height: 43px;
  width: 140px;
  border-bottom: 2px solid rgb(61, 103, 179);
}
.box-distance3 .distance3 span {
  display: inline-block;
  font-size: 16px;
  margin-left: 40px;
  margin-top: 20px
}
.box-distance3 .time3 span {
  display: inline-block;
  font-size: 10px;
  margin-left: 42px;
  margin-top: 3px;
}
.timeAfter3 {
  float: left;
  height: 90px;
}
.timeAfter3 .after3 {
  height: 54px;
}
.timeAfter3 .location7 {
  font-size: 13px;
  height: 36px;
  margin-left: 30px;
}
.timeAfter3 .after3 span {
  display: inline-block;
  font-size: 28px;
  margin-top: 20px;
  margin-left: 18px;
}
.train-price3 {
  float: left;
  width: 90px;
  height: 70px;
  margin-left: 18px;
  margin-top: 10px;
  border-left: 2px solid black;
}
.train-price3 span {
  display: inline-block;
  font-size: 12px;
  margin-top: 10px;
  margin-left: 15px;
}
.train-price3 .price3 {
  font-size: 28px;
  margin-left: 13px;
}
.train4 {
  width: 770px;
  height: 90px;
  margin: 10px auto;
  border-radius: 7px;
  border: 1px solid black;
}
.train4 .pic4 {
  float: left;
  width: 135px;
  height: 90px;
}
.train4 .pic4 img {
  width: 100%;
  height: 100%;
  margin-left: 35px;
  object-fit: contain;
}
.train-name4 {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  float: left;
  height: 70px;
  width: 90px;
  margin-left: 55px;
  margin-top: 10px;
  border-left: 2px solid black;
}
.train-name4 h3 {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  height: 70px ;
}
.timeBegin4 {
  float: left;
  height: 90px;
  width: 90px;
}
.timeBegin4 .begin4 {
  height: 54px;
  font-size: 28px;
}
.timeBegin4 .begin4 span {
  display: inline-block;
  margin-top: 20px;
  margin-left: 18px;
}
.timeBegin4 .location4 {
  height: 36px;
  text-align: center;
  font-size: 13px;
}
.box-distance4 {
  float: left;
  width: 140px;
  height: 90px;
}
.box-distance4 .distance4 {
  height: 43px;
  width: 140px;
  border-bottom: 2px solid rgb(61, 103, 179);
}
.box-distance4 .distance4 span {
  display: inline-block;
  font-size: 16px;
  margin-left: 40px;
  margin-top: 20px
}
.box-distance4 .time4 span {
  display: inline-block;
  font-size: 10px;
  margin-left: 42px;
  margin-top: 3px;
}
.timeAfter4 {
  float: left;
  height: 90px;
}
.timeAfter4 .after4 {
  height: 54px;
}
.timeAfter4 .location8 {
  font-size: 13px;
  height: 36px;
  margin-left: 30px;
}
.timeAfter4 .after4 span {
  display: inline-block;
  font-size: 28px;
  margin-top: 20px;
  margin-left: 18px;
}
.train-price4 {
  float: left;
  width: 90px;
  height: 70px;
  margin-left: 18px;
  margin-top: 10px;
  border-left: 2px solid black;
}
.train-price4 span {
  display: inline-block;
  font-size: 12px;
  margin-top: 10px;
  margin-left: 15px;
}
.train-price4 .price4 {
  font-size: 28px;
  margin-left: 13px;
}
.box {
  float: left;
  width: 800px;
  height: 170px;
  margin-left: 365px;
}
.box .choose {
  width: 260px;
  height: 30px;
  margin-top: 10px;
  margin-left: 20px;
}
.box .choose .tan {
  display: inline-block;
  font-family: 'icomoon';
  margin-left: 5px;
  margin-top: 5px;
  color:whitesmoke
}
.box .choose .font {
  font-size: 18px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  margin-left: 18px;
  color:whitesmoke
}
.box .where {
  margin-top: 15px;
  margin-left: 20px;
  float: left;
  height: 30px;
  width: 165px;
  border: 1px solid black;
  border-radius: 5px;
  background: white
} 
.box .where .where-begin {
  float: left;
  width: 40px;
  height: 23px;
  background-image: url(/src/assets/fly-on.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 3px;
  border: none;
  border-right: 1px solid black;
}
.box .where input {
  height: 28px;
  width: 120px;
  border: none;
  border-radius: 5px;
}
.box .dao-where {
  float: left;
  margin-top: 15px;
  margin-left: 9px;
  height: 31px;
  width: 165px;
  border: 1px solid black;
  border-radius: 5px;
  background: white
}
.box .dao-where .dao-where-end {
  float: left;
  width: 40px;
  height: 23px;
  background-image: url(/src/assets/fly-off.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 3px;
  border: none;
  border-right: 1px solid black;
}
.box .dao-where  input {
  height: 28px;
  width: 120px;
  border: none;
  border-radius: 5px;
  margin-left: 41px;
  margin-top: -4px;
}
.box .go {
  float: left;
  display: block;
  font-family: 'icomoon';
  margin-left: 10px;
  margin-top: 16px;
  color: white;
}

.box .back {
  float: left;
  display: block;
  font-family: 'icomoon';
  margin-left: -18px;
  margin-top: 30px;
  color:white;
}

.box .date {
  background-color: white;
  display: inline-block;
  margin-left: 50px;
  margin-top: 15px;
  width: 260px;
  height: 31px;
  border: 1px solid black;
  border-radius: 5px;
}

.box .date .calendar {
  float: left;
  display: block;
  font-family: 'icomoon';
  font-size: 23px;
  margin-left: 5px;
  margin-top: 3px;
}
.box .date em {
  float: left;
  margin-left: 4px;
  margin-top: -2px;
  font-size: 22px;
  font-style: normal;
  font-weight: lighter;
}
.box .date .begin-date {
  float: left;
  width: 100px;
  height: 29px;
  margin-top: 0;
  margin-left: 5px;
  border: none;
}
.box .current-price {
  float: left;
  display: block;
  margin-left: 20px;
  margin-top: 15px;
  width: 165px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid black;
}
.box .current-price input {
  font-family: 'icomoon';
  margin-left: 0px;
  height: 28px;
  width: 160px;
  border: none;
  border-radius: 5px;
}
.box .current-fly {
  float: left;
  margin-left: 20px;
  margin-top: 15px;
  width: 165px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid black;
}
.box .current-fly input {
  font-family: 'icomoon';
  margin-left: 0px;
  height: 28px;
  width: 160px;
  border: none;
  border-radius: 5px;
}
</style>